import "./login.css";

const Login = () => {
  const loginHandler = () => {
    window.location.href = `${process.env.REACT_APP_ServerUrl}/google`;
  };
  return (
    <>
      <div class="container">
        <div class="card">
          <form>
            <label for="username">Username</label>
            <input
              type="text"
              id="username"
              placeholder="Enter your username"
            />

            <label for="password">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Enter your password"
            />

            <button type="submit">Login</button>
            <div className="login-div" onClick={loginHandler}>
              Sign in with google
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
