import React, { useEffect, useState, useRef } from "react";
import "./add-new-project.css";
import { useProjectFieldData } from "../../Context/ProjectDetailsContext/ProjectDetailsContext.js";
import { Label, Button, InputField, Select } from "../../Components";
import axiosInstance from "../../Utils/axiosinstance";
import { logContext } from "../../App";
import { useContext } from "react";

const CreateProject = () => {
  const { fieldData, setFieldData } = useProjectFieldData();
  const [rerenderKey, setRerenderKey] = useState(0);
  const { logStatus, setLogStatus } = useContext(logContext);

  const [logData, setLogData] = useState([]);

  const [selectedClient, setSelectedClient] = useState("Select an option");

  // const [taskCreationStatus, setTaskCreationStatus] = useState(false);

  const [clientId, setClientId] = useState();

  // Check all the fields are entered or not
  const [emptyFields, setEmptyFields] = useState([]);

  const [clients, setClients] = useState([]);

  // Check all the fileds are entered or not

  const [validState, setValidState] = useState(false);

  const getClients = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_ServerUrl}/project/getClients`
    );
    // console.log(response.data.data);
    if (response.data.success) {
      setClients(response.data.data);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const handleInputChange = (e, label, index) => {
    const inputValue = e.target.value;
    if (label === "Client") {
      setClientId(e.target.value);

      const client_name = e.target.options[e.target.selectedIndex].text;
      setSelectedClient(client_name);
      setFieldData((prev) =>
        prev.map((field) =>
          field.label === label
            ? {
                ...field,
                value: client_name,
              }
            : field
        )
      );
    } else {
      setFieldData((prev) =>
        prev.map((field) =>
          field.label === label
            ? {
                ...field,
                value: inputValue,
              }
            : field
        )
      );
    }
  };

  function Decodeuint8arr(uint8array) {
    return new TextDecoder("utf-8").decode(uint8array);
  }

  const handleSubmit = async () => {
    const token = localStorage.getItem("timetracker-token");
    setLogData((oldArray) => [
      ...oldArray,
      `${new Date().toISOString()} execution started`,
    ]);
    setLogStatus(true);
    setRerenderKey((prevKey) => prevKey + 1);
    const postData = {};
    fieldData.forEach((item) => {
      postData[item.label] = item.value;
    });
    postData["clientId"] = clientId;

    // console.log(postData);
    const response = await fetch(
      `${process.env.REACT_APP_ServerUrl}/project/createProject`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
      }
    );
    const reader = response.body.getReader();
    while (true) {
      const { value, done } = await reader.read();
      if (done) break;
      // console.log(`Received: "${Decodeuint8arr(value)}"`);
      setLogData((oldArray) => [
        ...oldArray,
        `${new Date().toISOString()} ${Decodeuint8arr(value)}`,
      ]);
    }

    setFieldData((prev) =>
      prev.map((item) => ({
        ...item,
        value:
          item.label === "Active" || item.label === "Billable" ? false : "",
      }))
    );

    setSelectedClient("");
    // }
  };

  const handleCheckboxChange = (e, label) => {
    const inputValue = e.target.checked;

    setFieldData((prev) =>
      prev.map((field) =>
        field.label === label ? { ...field, value: inputValue } : field
      )
    );
  };

  // Displaying button and checking the options are filler or not.

  useEffect(() => {
    const emptyFields = fieldData
      .map((field, index) => (field.value === "" ? index : null))
      .filter((index) => index !== null);
    emptyFields.length === 0 ? setValidState(true) : setValidState(false);
    setEmptyFields(emptyFields);
  }, [fieldData]);

  // Handle Log Box

  const handleLogBox = () => {
    setLogStatus(false);
    setLogData([]);
  };

  return (
    <>
      {/* <div
        id="loader"
        className={`${validState === true ? "loader-active" : ""}`}
      >
        <div className="progress-bar">
          <div className="shadow"></div>
        </div>
      </div> */}
      <div
        className={`project-log__main-container ${
          logStatus === true ? "log-active" : ""
        }`}
      >
        <div className="project-log__container">
          <div className="project-log-image__container" onClick={handleLogBox}>
            <img src="./close.svg"></img>{" "}
          </div>
          <div className="project-log">
            {logData.map((log, index) => {
              const errorIndex = log.toLowerCase().indexOf("error :");
              const message =
                errorIndex !== -1 ? log.substring(errorIndex + 7) : log;

              return (
                <p key={index} className={errorIndex !== -1 ? "error-log" : ""}>
                  {message}
                </p>
              );
            })}
          </div>
        </div>

        <div className="add-new-project">
          {fieldData.map((data, index) => (
            <div key={index} className="add-new-project__field">
              <label className="add-new-project__label">{data.label}</label>
              {data.label === "Billable" || data.label === "Active" ? (
                <InputField
                  type="checkbox"
                  className="add-new-project__checkbox"
                  checked={data.value}
                  onChange={(e) => handleCheckboxChange(e, data.label)}
                />
              ) : data.label === "Client" ? (
                <Select
                  className={`add-new-project__select ${
                    emptyFields.includes(index) ? "empty-field" : ""
                  }`}
                  onChange={(e) => handleInputChange(e, data.label, index)}
                  options={clients}
                  selectedValue={selectedClient}
                  firstOption="Select a client*"
                  keyToForceRerender={rerenderKey}
                ></Select>
              ) : data.label === "StartDate" ? (
                <InputField
                  type="date"
                  className={`add-new-project__date ${
                    emptyFields.includes(index) ? "empty-field" : ""
                  }`}
                  value={data.value}
                  onChange={(e) => handleInputChange(e, data.label)}
                />
              ) : (
                <InputField
                  type="text"
                  className={`add-new-project__input ${
                    emptyFields.includes(index) ? "empty-field" : ""
                  }`}
                  value={data.value}
                  placeholder="Project name*"
                  onChange={(e) => handleInputChange(e, data.label)}
                />
              )}
            </div>
          ))}
          <div
            className={`add-new-project__create-button ${
              validState === true ? "active" : ""
            }`}
          >
            <Button
              onClick={() => {
                handleSubmit();
              }}
              value="Create"
            ></Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProject;
