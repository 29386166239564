import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import { loggedInContext } from "../../App";
import axios from "axios";

const AuthSuccess = () => {
  const { auth, setIsLoggedIn } = useContext(loggedInContext);
  //const urlParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  //const token = urlParams.get("timetracker-token");
  // console.log("token", token);

  let navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/404");
    }

    validateToken();
  }, []);

  const validateToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ServerUrl}/validateToken`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        localStorage.setItem("timetracker-token", token);
        setIsLoggedIn({ isLoggedIn: true, currentUser: response.data.user });
        navigate("/");
      }
    } catch (error) {
      // console.log("Error:", error);
    }
  };

  return <></>;
};

export default AuthSuccess;
