import React from "react";
import "./homepage.css";
import { useContext } from "react";
import {
  ChroneJobs,
  ProjectDetails,
  SideBar,
  Users,
  CreateProject,
} from "../index";
import { useSelectedItemContext } from "../../Context/ProjectDetailsContext/ProjectDetailsContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { loggedInContext } from "../../App";
import { useNavigate } from "react-router-dom";

const HomePage = ({ children }) => {
  const { auth, setIsLoggedIn } = useContext(loggedInContext);
  const navigate = useNavigate();
  const logOutHandler = () => {
    setIsLoggedIn({
      isLoggedIn: false,
      currentUser: null,
    });
    localStorage.removeItem("timetracker-token");
    navigate("/");
  };
  return (
    <>
      <div className="App">
        <div>
          <div className="dashBoard-container">
            <div className="dashBoard-container__side-bar">
              <SideBar />
            </div>

            <div className="dashBoard-container__dash-board">
              <div className="dashboard-container__header-container">
                <div
                  className="dashboard-container__header"
                  onClick={logOutHandler}
                >
                  <img src="./logout.svg"></img> Logout
                </div>
              </div>
              <div className="dashBoard-container__dash-board-component">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
