import React, { useEffect, useState } from "react";
import { Card, Button, Select, Toast } from "../../Components";
import "./project-details.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axiosInstance from "../../Utils/axiosinstance";
const ProjectDetails = () => {
  // State for managing the current users list

  const [activeButton, setActiveButton] = useState("");
  const [toastOpen, setToastOpen] = useState(false);
  const [severity, setToastSeverity] = useState("success");
  const [message, setToastMessage] = useState("");

  // State for managing the current project name

  const [selectedProject, setSelectedProject] = useState("");

  const [projectNames, setProjectNames] = useState([]);

  const [asaanaUsers, setAsaanaUsers] = useState([]);

  const [timeTackerUsers, setTimeTackerUsers] = useState([]);

  const getTTUsers = async (id) => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_ServerUrl}/project/getTTUsers?id=${id}`
    );
    // console.log(response);
    if (response.data.success) {
      setTimeTackerUsers(response.data.data);
    }
  };

  const getAsanaUsers = async (id) => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_ServerUrl}/project/getAsanaUsers?id=${id}`
    );
    // console.log("re", response.data.data);
    if (response.data.success) {
      setAsaanaUsers(response.data.data);
    }
  };
  const toastCloseHandler = () => {
    setToastOpen(false);
  };

  const syncProjectHandler = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_ServerUrl}/project/syncUsers?id=${selectedProject}`
    );
    if (response.data.success) {
      setToastOpen(true);
      setToastMessage("users synced successfully.");
    } else {
      setToastOpen(true);
      setToastMessage("users sync failed");
      setToastSeverity("error");
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_ServerUrl}/project/getTtProject`
    );
    if (response.data.success) {
      // console.log("project", response.data.data);
      setProjectNames(response.data.data);
    }
  };

  const handleInputChange = async (e) => {
    const inputValue = e.target.value;
    // console.log(inputValue);
    await getTTUsers(inputValue);
    setSelectedProject(inputValue);
    setActiveButton(inputValue === "" ? "" : "TimeTacker");
  };

  const handleUserList = async (buttonType) => {
    // setActiveButton(buttonType);
    selectedProject !== "" ? setActiveButton(buttonType) : setActiveButton();
    if (buttonType === "Asaana") {
      await getAsanaUsers(selectedProject);
      // console.log("asaana");
    } else if (buttonType === "TimeTacker") {
      // console.log("time");
    }
  };
  return (
    <>
      <Toast
        open={toastOpen}
        message={message}
        severity={severity}
        closeHandler={toastCloseHandler}
      ></Toast>
      <div className="project-details__container">
        <div className="project-details__container-data">
          <div className="project-details__container-heading">Project List</div>
          <div className="project-details__container-dropdown-section">
            <div className="project-details__container-dropdown">
              <Select
                firstOption={"Select a TT Project"}
                options={projectNames}
                className={"project-details__container-dropdown-list"}
                onChange={(e) => handleInputChange(e)}
              ></Select>
            </div>
          </div>
          <div className="project-details__container-buttons users-list">
            <span className={`${activeButton === "Asaana" ? "active" : ""}`}>
              <Button
                onClick={() => {
                  handleUserList("Asaana");
                }}
                value={"Asana"}
              ></Button>
            </span>
            <span
              className={`${activeButton === "TimeTacker" ? "active" : ""}`}
            >
              <Button
                onClick={() => {
                  handleUserList("TimeTacker");
                }}
                value={"Time Task"}
              ></Button>
            </span>
          </div>
        </div>
        <div
          className={`project-details__cards ${
            selectedProject !== "" ? "project-active" : ""
          }`}
        >
          <div
            className={`project-details__container-buttons sync-data ${
              activeButton == "TimeTacker" ? "sync-button-active" : ""
            }`}
          >
            <Button value={"Sync"} onClick={syncProjectHandler} />
          </div>
          <Card
            type={activeButton}
            projectDetails={
              activeButton == "Asaana" ? asaanaUsers : timeTackerUsers
            }
          />
        </div>
      </div>
    </>
  );
};

export default ProjectDetails;
