import React, { useState, useEffect, createContext } from "react";
import "./App.css";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import axiosInstance from "./Utils/axiosinstance";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  HomePage,
  Login,
  AuthSuccess,
  CreateProject,
  ProjectDetails,
  ChroneJobs,
  Users,
} from "./Pages";

import { useSelectedItemContext } from "./Context/ProjectDetailsContext/ProjectDetailsContext";
export const loggedInContext = createContext();
export const logContext = createContext();

function App() {
  const { selectedItem } = useSelectedItemContext();
  const { setSelectedItem } = useSelectedItemContext();
  const [logStatus, setLogStatus] = useState(false);
  const [auth, setIsLoggedIn] = useState({
    isLoggedIn: false,
    currentUser: null,
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("timetracker-token");
    if (token) {
      isTokenValid();
    } else {
      setIsLoggedIn({
        isLoggedIn: false,
        auth: null,
      });
      setIsLoading(false);
    }
  }, []);

  const isTokenValid = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_ServerUrl}/validateToken`
      );

      if (response.data.success) {
        setIsLoggedIn({
          isLoggedIn: true,
          currentUser: response.data.user,
        });
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );

  const withLayout = (component) => {
    const Compenent = component;
    return (
      <HomePage>
        <Compenent />
      </HomePage>
    );
  };

  return (
    <logContext.Provider value={{ logStatus, setLogStatus }}>
      <loggedInContext.Provider value={{ auth, setIsLoggedIn }}>
        <div
          className={`overlay ${logStatus === true ? "overlay-active" : ""}`}
        ></div>
        <Routes>
          {auth.isLoggedIn ? (
            <>
              <Route path="/" element={withLayout(CreateProject)} />
              <Route path="/projects" element={withLayout(ProjectDetails)} />
              <Route path="/chrone-jobs" element={withLayout(ChroneJobs)} />
              <Route path="/users" element={withLayout(Users)} />
            </>
          ) : (
            <>
              <Route path="/" element={<Login></Login>}></Route>
              <Route
                path="/auth/success"
                element={<AuthSuccess></AuthSuccess>}
              ></Route>
            </>
          )}
        </Routes>
      </loggedInContext.Provider>
    </logContext.Provider>
  );
}

export default App;
