import React, { useEffect, useState } from "react";
import "./menuitem.css";
import { useSelectedItemContext } from "../../Context/ProjectDetailsContext/ProjectDetailsContext";
import { Link, useNavigate } from "react-router-dom";

const MenuItem = ({ menuList }) => {
  const navigate = useNavigate();
  const { selectedItem } = useSelectedItemContext();

  const { setSelectedItem } = useSelectedItemContext();

  const [currentMenuItem, setCurrentMenuItem] = useState("/");

  useEffect(() => {
    navigate(currentMenuItem);
  }, [currentMenuItem, navigate]);

  const selectMenu = (index, path) => {
    setSelectedItem(index);
    setCurrentMenuItem(path);
  };

  return (
    <div className="menu">
      {menuList.map((item, index) => (
        <div
          key={index}
          className={`menu-item ${selectedItem === index ? "active" : ""}`}
          onClick={() => selectMenu(index, item.path)}
        >
          <div className="menu-item__active"></div>
          <img src={item.image}></img>
          <h3>{item.name}</h3>
        </div>
      ))}
    </div>
  );
};

export default MenuItem;
