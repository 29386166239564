import React, { useEffect, useState } from "react";

const Select = ({
  className,
  onChange,
  options,
  selectedValue,
  firstOption,
  keyToForceRerender
}) => {
  return (
    <select className={className} onChange={onChange} >
    <option ></option>
      {options.map((client, index) => (
        <option key={index} value={client.id}>
          {client.name}
        </option>
      ))}
    </select>
  );
};

export default Select;
