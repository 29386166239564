import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import axiosInstance from "../../Utils/axiosinstance";
import { Button, TextCard } from "../../Components";
import "./chrone-jobs.css";

const ChroneJobs = () => {
  const [createScheulerData, setCreateScheulerData] = useState([]);
  const [updateScheulerData, setUpdateScheulerData] = useState([]);
  const [deleteScheulerData, setDeleteScheulerData] = useState([]);
  const [activeTab, serActiveTab] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [buttonType, setButtonType] = useState("filter");
  const [isLoading, setIsLoading] = useState(false);
  const [nextForwardToken, setNextForwardToken] = useState(null);
  const [nextBackwardToken, setNextBackwardToken] = useState(null);

  const handleClearDates = async () => {
    setStartDate("");
    setEndDate("");
    await handleTabClick(activeTab);
  };

  const handleFilterDates = async () => {
    try {
      await handleTabClick(activeTab); // Trigger the API request with updated dates
    } catch (error) {
      console.error("Error fetching tab content:", error);
    }
  };

  const handleTabClick = async (index, token) => {
    setIsLoading(true);
    serActiveTab(index);
    const tabRoutes = {
      0: "createLog",
      1: "updateLog",
      2: "deleteLog",
    };
    const formattedStartDate = startDate
      ? Math.floor(new Date(startDate).getTime())
      : null;
    const formattedEndDate = endDate
      ? Math.floor(new Date(endDate).getTime())
      : null;

    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_ServerUrl}/log/${tabRoutes[index]}`,
        {
          params: {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            token: token,
          },
        }
      );
      // console.log("Data", response.data);
      if (index == 0) {
        setCreateScheulerData(response.data.events);
      } else if (index == 1) {
        setUpdateScheulerData(response.data.events);
      } else {
        setDeleteScheulerData(response.data.events);
      }
      setNextForwardToken(response.data.nextForwardToken);
      setNextBackwardToken(response.data.nextBackwardToken);
    } catch (error) {
      console.error("Error fetching tab content:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleTabClick(activeTab);
  }, []);

  return (
    <div className="chrone-jobs-container">
      <div className="chrone-jobs-buttons">
        <Button
          value={"Create"}
          onClick={() => {
            handleTabClick(0);
          }}
          isActive={activeTab === 0}
        ></Button>
        <Button
          value={"Update"}
          onClick={() => {
            handleTabClick(1);
          }}
          isActive={activeTab === 1}
        ></Button>
        <Button
          value={"Delete"}
          onClick={() => {
            handleTabClick(2);
          }}
          isActive={activeTab === 2}
        ></Button>
      </div>
      <div className="date-picker-container">
        <div>Start Date</div>
        <input
          type="date"
          className="date-picker"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <div>End Date</div>
        <input
          className="date-picker"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        {startDate && endDate && (
          <div onClick={handleFilterDates} className="cButton">
            Filter
          </div>
        )}
        {startDate && endDate && (
          <div onClick={handleClearDates} className="cButton">
            Clear
          </div>
        )}
      </div>
      <div className="chrone-jobs-text-card">
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "500px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <TextCard
            data={
              activeTab === 0
                ? createScheulerData
                : activeTab === 1
                ? updateScheulerData
                : deleteScheulerData
            }
          ></TextCard>
        )}
      </div>
      <div className="page-buttons">
        {nextBackwardToken && (
          <div
            className="cButton"
            onClick={() => handleTabClick(activeTab, nextBackwardToken)}
          >
            Previous
          </div>
        )}
        {nextForwardToken && (
          <div
            className="cButton"
            onClick={() => handleTabClick(activeTab, nextForwardToken)}
          >
            Next
          </div>
        )}
      </div>
    </div>
  );
};
export default ChroneJobs;
