import React from "react";
import "./TextCard.css";
const TextCard = ({ data }) => {
  return (
    <>
      {data.length === 0 && <div>no logs found for the selected date</div>}

      {data.map((item, index) => {
        const [type, message] = item.message.split(" : ");
        const messageClass = type === "[error]" ? "error" : "info";

        return (
          <div key={index} className={messageClass}>
            {message}
          </div>
        );
      })}
    </>
  );
};

export default TextCard;
